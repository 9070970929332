<template>
  <div v-if="userObj !== null">
    <v-card class="edit-card">
      <v-card-title class="title">
        <span>Basic Information</span>
        <a @click="editUserBasicInfo()">Edit</a>
      </v-card-title>
      <v-card-text class="data">
        <div>
          <strong>{{ userName }}</strong>
        </div>
        <div v-if="title">Title: {{ title }}</div>
        <div>
          Role: <strong>{{ roleName }}</strong>
        </div>
        <div>
          Phone: <strong>{{ phone }}</strong>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="edit-card">
      <v-card-title class="title">
        <span>Email</span>
        <span v-if="pendingLogin" class="warning">
          <v-icon small> fas fa-exclamation-triangle </v-icon>
          Pending Change
        </span>
        <a v-else @click="editUserEmail()">Edit</a>
      </v-card-title>
      <v-card-text class="data">
        <div>
          <strong>{{ email }}</strong>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="edit-card">
      <v-card-title class="title">
        <span>Password</span>
        <a v-if="isCurrentUser" @click="editUserPassword()">Edit</a>
      </v-card-title>
      <v-card-text class="data">
        <div>********</div>
      </v-card-text>
    </v-card>
    <v-card class="edit-card" v-if="canUploadDocuments">
      <v-card-title class="title">
        <span> Documents </span>
      </v-card-title>
      <v-card-text class="data">
        <v-row>
          <v-col class="col-12">
            <span class="warning">
              <v-icon small> fas fa-exclamation-triangle </v-icon>
              (required for advisors)
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 upload-col">
            <div>
              <strong> ADV 2B: </strong>

              <span class="upload-date" v-if="getAsset(ASSET_KEYS.ADV2B)">
                <a @click="downloadDocument(ASSET_KEYS.ADV2B)">
                  {{ adv2bName }}
                </a>
                <b> ({{ adv2bDate }}) </b>
              </span>
              <span v-else> {{ NO_DATE }} </span>
            </div>
            <div>
              <v-file-input
                v-model="documents.adv2b"
                prepend-icon=""
                class="faux-upload"
                ref="upload-2B"
                hide-details="auto"
                :clearable="false"
                accept="application/pdf"
                @change="upload2B"
              >
                <template v-slot:prepend-inner>
                  <a
                    id="upload-2b"
                    class="btn mini"
                    style="display: block"
                    @click="() => $refs['upload-2B'].$refs.input.click()"
                  >
                    <v-icon small class="pdf"> fas fa-file-pdf </v-icon>
                    choose file
                  </a>
                </template>
              </v-file-input>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="edit-card" v-if="canAuthenticateIntegration">
      <v-card-title class="title">
        <span>
          <span>
            {{ this.currentUser.selectedCRM }}
          </span>
          <div class="crm-logo-wrapper">
            <img
              class="crm-logo"
              :alt="`${this.currentUser.selectedCRM} logo`"
              :src="`${publicPath}images/${selectedCrmLogo}`"
            />
          </div>
        </span>
        <span class="action-buttons">
          <span v-if="!isCrmLinked" class="incomplete">
            <v-icon small class="mr-2"> fas fa-exclamation-triangle </v-icon>
            Incomplete
          </span>
          <a v-if="isCurrentUser && !isCrmLinked" @click="linkCrm()"> Link </a>
          <a
            class="unlink"
            v-if="isCurrentUser && isCrmLinked"
            @click="unlinkCrm()"
          >
            Unlink
          </a>
        </span>
      </v-card-title>
      <v-card-text class="data">
        <div v-if="isCrmLinked" class="verified">
          <v-icon>fas fa-check-circle</v-icon>
          <span>Verified</span>
        </div>
        <div v-else class="not-verified">
          <v-icon>fas fa-times-circle</v-icon>
          <span>Not Verified</span>
        </div>
      </v-card-text>
    </v-card>
    <!-- Docusign -->
    <v-card
      class="edit-card"
      v-if="
        canAuthenticateIntegration &&
        docusignStatus &&
        docusignStatus.enabled &&
        docusignStatus.hasADV2B
      "
    >
      <v-card-title class="title">
        <span>
          <img
            class="docusign-logo"
            height="22"
            :src="`${publicPath}images/docusign-logo.png`"
          />
        </span>
        <span class="action-buttons">
          <span
            v-if="!isDocusignComplete"
            class="incomplete"
            :class="{ optional: docusignStatus.orgDocusignConfigured }"
          >
            <v-icon small class="mr-2"> fas fa-exclamation-triangle </v-icon>
            <span v-if="docusignStatus.orgDocusignConfigured">Optional</span>
            <span v-else>Incomplete</span>
          </span>
          <a
            v-if="isCurrentUser && !isDocusignComplete"
            @click="linkDocusign()"
          >
            Link
          </a>
          <a
            v-if="isCurrentUser && isDocusignComplete"
            class="unlink"
            @click="unlinkDocusign()"
          >
            Unlink
          </a>
        </span>
      </v-card-title>
      <!-- Docusign Account Info -->
      <v-card-text class="data">
        <div v-if="isDocusignComplete" class="linked">
          <v-icon>fas fa-check-circle</v-icon>
          <span>Linked</span><br />
          <v-row>
            <v-col cols="3" class="field-label">Username</v-col>
            <v-col cols="9"
              ><span v-html="docusignStatus.accountUsername"></span
            ></v-col>
          </v-row>
          <v-row v-if="docusignStatus && (!docusignAccounts || docusignAccounts.length === 1)">
            <v-col cols="3" class="field-label">Selected Account</v-col>
            <v-col cols="9"
              ><span v-html="docusignStatus.accountName"></span>
            </v-col>
          </v-row>
          <v-row v-if="docusignAccounts && docusignStatus && docusignAccounts.length > 1">
            <v-col cols="3" class="field-label">Selected Account</v-col>
            <v-col cols="6">
              <v-select
                class="align-top"
                v-model="selectedDocusignAccount"
                :items="docusignAccounts"
                item-text="accountName"
                item-value="accountId"
                return-object
              ></v-select>
            </v-col>
            <v-col cols="3">
              <a
                class="btn mini"
                :class="{
                  disabled:
                    selectedDocusignAccount.accountId ===
                    docusignStatus.accountId,
                }"
                @click="saveSelectedAccount"
                id="save-account"
                >Change Account</a
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="field-label">Account Ownership</v-col>
            <v-col cols="9">
              <v-btn-toggle
                v-model="docusignStatus.accountOwnership"
                @change="saveAccountOwnership"
                dense
                mandatory
              >
                <v-btn value="Firm">Firm</v-btn>
                <v-btn value="Schwab">Schwab</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </div>
        <div
          v-else
          class="not-linked"
          :class="{ optional: docusignStatus.orgDocusignConfigured }"
        >
          <v-icon>fas fa-times-circle</v-icon>
          <span>Not Linked</span>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="edit-card" v-if="shouldCompleteCompanyProfile">
      <v-card-title class="title">
        <span>Next Steps</span>
      </v-card-title>
      <v-card-text class="data">
        <v-row>
          <v-col class="col-12">
            <span class="next-steps"
              >Congratulations, your user profile is complete!</span
            >
            <span>
              <a
                @click="completeCompanyProfile()"
                class="btn mini complete-company_profile"
              >
                Complete Company Profile
              </a>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import { EventService } from '@/services/event.service';
import EventListener from '@/common/mixins/eventlistener.mixin';
import Themable from '@/common/mixins/themable.mixin';
import PublicAssets from '@/common/mixins/publicasset.mixin';
import {
  MENUS,
  USER_PROFILE_SECTION_TYPES,
  ASSET_KEYS,
  formatDate,
  US_DATE_SHORT,
  NO_DATE,
  USER_ROLES,
  SESSION_TIMEOUT_ERROR,
  formatPhoneLocal,
} from '@/common/constants';
import {
  GET_USER,
  LINK_WEALTHBOX,
  UNLINK_CRM,
  GET_DOCUSIGN_CONSENT_URL,
  POST_DOCUSIGN_USER_CONSENT,
  CHOOSE_DOCUSIGN_USER_ACCOUNT,
  CHOOSE_DOCUSIGN_USER_ACCOUNT_OWNERSHIP,
  UNLINK_DOCUSIGN_USER,
  GET_CURRENT_USER,
  DOWNLOAD_USER_ASSET,
  POST_USER_ASSET,
  LINK_SALESFORCE,
  LINK_PRACTIFI
} from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { PermissionService, PERMISSIONS } from '@/services/permission.service';

export default {
  props: {
    id: Number,
    advisorId: String,
    organisationId: String,
  },
  data: () => ({
    userId: null,
    userObj: null,
    loading: false,
    assets: null,
    docusignStatus: {
      hasADV2B: null,
      accountUsername: null,
      accountName: null,
      accountOwnership: null,
      enabled: null,
      accountId: null,
      orgDocusignConfigured: null,
    },
    redtailStatus: null,
    docusignAccounts: null,
    selectedDocusignAccount: null,
    documents: {
      adv2b: null,
    },
  }),

  mixins: [PublicAssets, Themable, EventListener],
  computed: {
    ...mapGetters(['currentUser', 'currentCompany']),
    userName() {
      return `${this.userObj.user.firstName} ${this.userObj.user.lastName}`;
    },
    sendStatus() {
      return this.user.canSend ? 'Allowed' : 'Disabled';
    },
    title() {
      return this.user.title;
    },
    roleName() {
      return this.role.name;
    },
    email() {
      return this.user.email;
    },
    pendingLogin() {
      return this.user.pendingLogin !== null;
    },
    user() {
      return this.userObj ? this.userObj.user : {};
    },
    role() {
      return this.userObj ? this.userObj.roles[0] : '';
    },
    phone() {
      return formatPhoneLocal(this.user.mobilePhone);
    },
    isCurrentUser() {
      try {
        return this.userId && this.userId === this.currentUser.user.id;
      } catch {
        return true;
      }
    },
    canAuthenticateIntegration() {
      if (this.isCurrentUser) {
        const role = this.currentUser.roles[0];
        return role.id !== USER_ROLES.PRODUCT_ADMIN.id;
      }

      return true;
    },
    isCrmLinked() {
      return this.userObj.user.crmLinked;
    },
    canUploadDocuments() {
      try {
        return PermissionService.hasPermission(
          PERMISSIONS.USER.MANAGE_USER_PROFILE,
        );
      } catch {
        // Do Nothing
      }
      return false;
    },
    adv2bName() {
      const asset = this.getAsset(ASSET_KEYS.ADV2B);
      return asset ? asset.assetId : '';
    },
    adv2bDate() {
      const asset = this.getAsset(ASSET_KEYS.ADV2B);
      return asset ? formatDate(asset.createdOn, US_DATE_SHORT) : '';
    },
    shouldCompleteCompanyProfile() {
      return PermissionService.shouldCompleteCompanyProfile(this.currentUser);
    },
    docusignConsentCode() {
      return this.$route.query.code;
    },
    isDocusignComplete() {
      return this.userObj.user.docusignLinked;
    },
    selectedCrmLogo() {
      let asset;
      switch (this.currentCompany.selectedCRM) {
        case 'Wealthbox':
          asset = 'wealthbox-logo.svg';
          break;
        case 'Redtail':
          asset = 'redtail-logo.svg';
          break;
        case 'Salesforce':
          asset = 'salesforce-logo.svg';
          break;
        case 'Practifi':
          asset = 'practifi-logo.png';
          break;
        default:
          asset = '';
          break;
      }
      console.log('Selected CRM Logo:', asset);
      return asset;
    },
  },
  methods: {
    /* Get a user asset */
    getAsset(type) {
      return (
        this.userObj && this.userObj.assets.find((a) => a.assetType === type)
      );
    },

    /* Emit event to display dialog for editing User Basic info */
    editUserBasicInfo() {
      EventService.emit('display-add-edit-user-modal', this.userObj);
    },

    /* Emit event to display dialog for editing user email */
    editUserEmail() {
      EventService.emit(
        'display-add-edit-user-modal',
        this.userObj,
        USER_PROFILE_SECTION_TYPES.EMAIL,
      );
    },

    /* Emit event to display dialog for changing user password */
    editUserPassword() {
      EventService.emit(
        'display-add-edit-user-modal',
        this.userObj,
        USER_PROFILE_SECTION_TYPES.PASSWORD,
      );
    },

    /* Emit event to display dialog for editing redtail credentials */
    linkRedtailInfo() {
      EventService.emit(
        'display-add-edit-user-modal',
        this.userObj,
        USER_PROFILE_SECTION_TYPES.REDTAIL,
      );
    },

    linkWealthboxInfo() {
      const payload = {
        advisorId: this.currentUser.user.id,
      };

      this.$store
        .dispatch(LINK_WEALTHBOX, payload)
        .then((data) => {
          window.location.replace(data);
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Failed to authenticate with Wealthbox.',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          EventService.emit('reload-user-profile', this.userId);
        });
    },

    linkCrm() {
      switch (this.currentCompany.selectedCRM) {
        case 'Wealthbox':
          this.linkWealthboxInfo();
          break;
        case 'Redtail':
          this.linkRedtailInfo();
          break;
        case 'Salesforce':
          this.linkSalesforceInfo(1);
          break;
        case 'Practifi':
          this.linkSalesforceInfo(0);
          break;
        default:
          break;
      }
    },

    linkSalesforceInfo(num) {
      const payload = {
        advisorId: this.userId,
      };

      this.$store
        .dispatch(num ? LINK_SALESFORCE : LINK_PRACTIFI, payload)
        .then((data) => {
          window.location.replace(data);
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Failed to authenticate with Salesforce.',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          EventService.emit('reload-user-profile', this.userId);
        });
    },

    unlinkCrm() {
      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: `Are you sure you want to unlink ${this.currentCompany.selectedCRM}?`,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.$store
            .dispatch(UNLINK_CRM)
            .then(() => {
              NotificationService.alert({
                type: NOTIFICATION_TYPES.SUCCESS,
                title: 'Success',
                message: `Successfully unlinked ${this.currentCompany.selectedCRM}. You will need to Link to re-authenticate and complete your profile.`,
                okTitle: 'Ok',
                okMethod: () => {
                  this.dialog = false;
                  this.reloadCurrentUser();
                },
              });
            })
            .catch((response) => {
              NotificationService.alert(
                {
                  type: NOTIFICATION_TYPES.ERROR,
                  title: 'Error',
                  message: `Error unlinking from ${this.currentCompany.selectedCRM}.`,
                  okTitle: 'Ok',
                },
                response,
              );
            })
            .finally(() => {
              this.loadUser();
            });
        },
      });
    },

    /* Process upload of 2B */
    upload2B(selectedFile) {
      this.uploadDocument(ASSET_KEYS.ADV2B, selectedFile);
    },

    /* Upload a document */
    uploadDocument(type, file) {
      const payload = {
        userId: this.id,
        assetKey: type,
        file: file,
      };

      this.$store
        .dispatch(POST_USER_ASSET, payload)
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error uploading document!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.loadUserData(this.id);
        });
    },

    /* Download a document */
    downloadDocument(type) {
      const asset = this.getAsset(type);
      if (!asset) return;
      const payload = {
        id: this.userId,
        assetType: type,
        assetId: asset.assetId,
        name: asset.name,
        mediaType: asset.mediaType,
      };
      this.$store
        .dispatch(DOWNLOAD_USER_ASSET, payload)
        .then((response) => {
          if (response) {
            window.open(response, '_blank');
          }
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error downloading document!',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },
    isPractifiConnected() {
      if (this.currentCompany && this.currentCompany.selectedCRM === 'Practifi' && this.currentCompany.crmConfiguration) {
        if (!this.currentCompany.crmConfiguration.practifiInstalled) {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Practifi is not available or not configured.',
              okTitle: 'Ok',
              okMethod: () => {},
            },
          );
        }
      }
    },
    isSalesforcePackageInstalled() {
      if (this.currentCompany && this.currentCompany.selectedCRM === 'Salesforce' && this.currentCompany.crmConfiguration) {
        if (!this.currentCompany.crmConfiguration.packageInstalled) {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Salesforce is not available or not configured',
              okTitle: 'Ok',
              okMethod: () => {},
            },
          );
        }
      }
    },

    /* Load the user data for a specific id */
    loadUser(id) {
      this.loading = true;
      const action = this.isCurrentUser ? GET_CURRENT_USER : GET_USER;

      this.$store
        .dispatch(action, id)
        .then((response) => {
          this.userObj = response;
          this.getDocusignStatus();
          const integrations = this.currentUser.integrations;
          const practifiIntegration = integrations.find((el) => el.integrationConfig.name === 'Practifi');
          if (practifiIntegration) {
            this.isPractifiConnected();
          }
          const salesforceIntegration = integrations.find((el) => el.integrationConfig.name === 'Salesforce');
          if (salesforceIntegration) {
            this.isSalesforcePackageInstalled();
          }
        })
        .catch((response) => {
          if (response == SESSION_TIMEOUT_ERROR) {
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Session Timeout',
                message: 'Your session has timed out. Please log in again.',
                okTitle: 'Ok',
                okMethod: () => {
                  this.$router.push({ name: MENUS.LOGIN });
                },
              },
              response,
            );
          } else {
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Error',
                message: 'error retrieving user!',
                okTitle: 'Ok',
                okMethod: () => {
                  this.$router.push({ name: MENUS.USERS.id });
                },
              },
              response,
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reloadCurrentUser() {
      this.$store.dispatch(GET_CURRENT_USER).then(() => {
        this.getDocusignStatus();
      });
    },

    completeCompanyProfile() {
      const routeObj = {
        name: MENUS.COMPANY_PROFILE.id,
        params: { id: this.currentUser.organization.id },
      };
      this.$router.push(routeObj).catch(() => {});
    },

    /* Load all User Data */
    loadUserData(id) {
      this.loadUser(id);
      this.getDocusignStatus();
    },

    /* Check Docusign Status */
    getDocusignStatus() {
      const { integrations } = this.userObj || {};

      if (isEmpty(integrations)) {
        this.docusignStatus.enabled = !this.currentCompany.docusignDisabled;
        this.docusignStatus.orgDocusignConfigured =
          this.currentCompany && this.currentCompany.docusignLinked;
        this.docusignStatus.hasADV2B =
          this.getAsset(ASSET_KEYS.ADV2B) !== undefined;
        this.docusignStatus.accountUsername = '';
        this.docusignStatus.accountName = '';
        this.docusignStatus.accountOwnership = 'Firm';
        this.docusignStatus.accountId = '';
        return;
      }

      const { integrationConfig } =
        integrations.find(
          ({ integrationType }) => integrationType === 'DocumentSigning',
        ) || {};

      if (this.currentCompany && this.docusignStatus) {
        this.docusignStatus.enabled = !this.currentCompany.docusignDisabled;
        this.docusignStatus.orgDocusignConfigured =
          this.currentCompany && this.currentCompany;
        this.docusignStatus.hasADV2B =
          this.getAsset(ASSET_KEYS.ADV2B) !== undefined;

        if (integrationConfig) {
          this.selectedDocusignAccount = {
            accountId: integrationConfig.accountId,
            accountName: integrationConfig.accountName,
          }
          this.docusignAccounts = integrationConfig.accounts;
          this.docusignStatus.accountUsername = integrationConfig.username;
          this.docusignStatus.accountName = integrationConfig.accountName;
          this.docusignStatus.accountId = integrationConfig.accountId;
          this.docusignStatus.accountOwnership =
            integrationConfig.accountOwnership;
          this.docusignStatus.enabled =
            !this.currentCompany.docusignDisabled && integrationConfig.enabled;
        }
      }
    },

    /* Link Docusign Account */
    linkDocusign() {
      const payload = {
        advisorId: this.currentUser.user.id,
      };

      this.$store
        .dispatch(GET_DOCUSIGN_CONSENT_URL, payload)
        .then((data) => {
          window.location.replace(data);
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error contacting DocuSign !',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          EventService.emit('reload-user-profile', this.userId);
        });
    },

    /* Unlink Docusign Account */
    unlinkDocusign() {
      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: `Are you sure you want to unlink DocuSign?`,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.performUnlinkDocusign();
        },
      });
    },

    /* Perform Unlink Docusign Account */
    performUnlinkDocusign() {
      this.$store
        .dispatch(UNLINK_DOCUSIGN_USER)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message:
              'Successfully unlinked DocuSign. You will need to Link to re-authenticate and complete your profile.',
            okTitle: 'Ok',
            okMethod: () => {
              this.dialog = false;
            },
          });

          if (this.isCurrentUser) {
            this.reloadCurrentUser();
          }
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error unlinking from DocuSign.',
              okTitle: 'Ok',
              okMethod: () => {
                this.dialog = false;
              },
            },
            response,
          );
        })
        .finally(() => EventService.emit('reload-user-profile', this.userId));
    },

    /* Post the Consent code returned from DocuSign */
    postDocusignConsent() {
      this.$store
        .dispatch(POST_DOCUSIGN_USER_CONSENT, this.docusignConsentCode)
        .then((data) => {
          this.docusignAccounts = data.accounts;
          this.selectedDocusignAccount = data.accounts[0];
          var defaultAccount = data.accounts.find((account) => {
            account.is_default === 'true';
          });
          if (defaultAccount) this.selectedDocusignAccount = defaultAccount;
          // this.loadDocusignStatus(this.userId);
          if (this.isCurrentUser) {
            this.reloadCurrentUser();
          }
        })
        .catch(() => {});
    },

    saveSelectedAccount() {
      if (
        this.selectedDocusignAccount.accountId !==
        this.docusignStatus.accountId
      ) {
        this.$store
          .dispatch(CHOOSE_DOCUSIGN_USER_ACCOUNT, this.selectedDocusignAccount)
          .catch((response) => {
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Error',
                message: 'Error changing DocuSign account!',
                okTitle: 'Ok',
              },
              response,
            );
          })
          .finally(() => {
            EventService.emit('reload-user-profile', this.userId);
          });
      }
    },

    saveAccountOwnership() {
      const payload = {
        advisorId: this.userObj.user.id,
        ownership: this.docusignStatus.accountOwnership,
      };

      this.$store
        .dispatch(CHOOSE_DOCUSIGN_USER_ACCOUNT_OWNERSHIP, payload)
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error changing DocuSign account ownership!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          EventService.emit('reload-user-profile', this.userId);
        });
    },
  },
  created() {
    this.NO_DATE = NO_DATE;
    this.ASSET_KEYS = ASSET_KEYS;

    // Load initial user data
    if (this.id) {
      this.userId = this.id;
    } else {
      // id was not passed in the URI, use the current user
      this.userId = this.currentUser.user.id;
    }

    this.loadUserData(this.userId);

    if (this.docusignConsentCode) {
      this.postDocusignConsent();
    }

    // Listen for reload
    this.listen('reload-user-profile', (id) => {
      this.loadUserData(id);
    });
  },
};
</script>

<style lang="scss" scoped>
.edit-card {
  padding: 32px;
  margin-bottom: 20px;

  .title {
    color: var(--color-text);
    margin-bottom: 1.5rem;
    padding: 0 0 1rem 0;
    border-bottom: 1px solid var(--secondary-color);
    font-size: 20px;
    font-weight: 500;
    justify-content: space-between;
    align-items: flex-start;

    a {
      font-size: 16px;
    }

    .warning {
      color: var(--primary-color);
      font-size: 14px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 6px;
        color: var(--color-warning);
      }
    }

    .action-buttons {
      .incomplete {
        &.optional {
          color: var(--color-warning);

          svg {
            color: var(--color-warning);
          }
        }

        font-size: 16px;
        color: var(--error-color);

        svg {
          color: var(--error-color);
          margin-top: -3px;
          cursor: text;
        }
      }

      a {
        display: block;
        text-align: right;

        &.unlink {
          color: var(--primary-color);
        }
      }
    }

    .crm-logo-wrapper {
      display: inline-flex;
      align-items: center;
      margin-left: 12px;
      min-width: 100px;
      min-height: 30px;
      max-height: 50px;
    }

    .crm-logo {
      width: auto;
      max-width: 120px;
      height: auto;
      max-height: 35px;
      object-fit: contain;
      vertical-align: middle;
    }
  }

  .data {
    color: var(--color-text);
    padding: 0;
    font-size: 16px;
    line-height: 32px;

    .complete-company_profile {
      max-width: 250px;
    }

    .warning {
      color: var(--primary-color);
      display: flex;
      align-items: center;

      svg {
        margin-right: 6px;
        color: var(--color-warning);
      }
    }

    .linked {
      color: var(--primary-color);

      span {
        margin-left: 8px;
        font-weight: 500;
      }

      ::v-deep .v-icon {
        color: var(--primary-color);
        font-size: 32px;
      }

      .field-label {
        text-align: right;
      }
    }

    .not-linked {
      &.optional {
        color: var(--color-warning);

        ::v-deep .v-icon {
          color: var(--color-warning);
        }
      }

      color: var(--error-color);

      span {
        margin-left: 8px;
        font-weight: 500;
      }

      ::v-deep .v-icon {
        color: var(--error-color);
        font-size: 32px;
      }
    }

    .verified {
      color: var(--primary-color);

      span {
        margin-left: 8px;
        font-weight: 500;
      }

      ::v-deep .v-icon {
        color: var(--primary-color);
        font-size: 32px;
      }
    }

    .not-verified {
      color: var(--error-color);

      span {
        margin-left: 8px;
        font-weight: 500;
      }

      ::v-deep .v-icon {
        color: var(--error-color);
        font-size: 32px;
      }
    }

    .upload-date {
      font-size: 14px;
    }

    strong {
      padding-right: 4px;
    }

    @media screen and (min-width: 770px) {
      .upload-col {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .faux-upload {
      display: inline-block;
      padding: 0px;

      ::v-deep .v-input__slot {
        &:before,
        &:after {
          border-color: transparent !important;
          text-decoration-color: transparent !important;
          background-color: transparent;
        }

        .v-input__append-inner {
          width: 0px;
        }

        .v-text-field__slot {
          display: none;
        }

        .pdf {
          margin-right: 8px;
          color: #fff;
        }
      }
    }

    ::v-deep {
      .missing {
        font-weight: 500;
        font-size: 14px;
        color: var(--error-color);
      }
    }
  }
}

.v-text-field.align-top {
  padding-top: 0;
  margin-top: 0;
}
</style>
